<template>
  <div class="pay h-backgroud-color_body">
    <br />
    <div class="box h-text-center">
      <div v-if="orderData">
        <div v-if="orderData && orderData.payStatus === '3'" class="h-font-sm h-text-secondary-color h-m-t-5">当前订单<span class="h-success-color h-p-l-5 h-p-r-5">支付成功</span></div>
        <div v-else class="h-font-sm h-text-secondary-color h-m-t-5">当前订单<span class="h-price-color h-p-l-5 h-p-r-5">已失效</span></div>
      </div>
    </div>
    <div v-if="orderData" class="box">
      <div class="row h-flex h-row-between">
        <span>支付金额</span>
        <span v-if="orderData && orderData.payStatus === '3'" class="h-price-color">￥{{ orderData.finalSaleAmount | amountFilter }}</span>
        <span v-else class="h-disable-color">￥ ---</span>
      </div>
      <div class="row h-flex h-row-between">
        <span>订单号</span>
        <span>{{ orderData.orderNo }}</span>
      </div>
      <div class="row h-flex h-row-between">
        <span>客户名称</span>
        <span>{{ orderData.customerName }}</span>
      </div>
      <div class="row h-flex h-row-between">
        <span>车牌号</span>
        <span>{{ orderData.plateNo }}</span>
      </div>
      <div class="row h-flex h-row-between">
        <span>服务名称</span>
        <span>{{ orderData.productNames }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/api/service";
import { mapState } from "vuex";
import goBack from "@/mixins/goBack";
export default {
  mixins: [goBack],
  filters: {
    amountFilter(value) {
      if (value) {
        return parseFloat(value).toFixed(2);
      }
      return "0.00";
    }
  },
  data() {
    return {
      pageData: {
        payLoading: false
      },
      orderId: null,
      preOrderId: null,
      payWay: "WXJS0007MIPP",
      interval: null,
      orderTime: 30,
      orderData: null
    };
  },
  computed: {
    ...mapState("user", ["info"])
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { orderId } = this.$route.query;
      if (!orderId) {
        return false;
      }
      this.orderId = orderId;
      try {
        const res = await request({
          url: `/afis-carservice/order/payOrderInfo/${orderId}`,
          method: "GET"
        });
        this.orderData = res;
        const coverages = res.coverages;
        if (coverages && coverages.length > 0) {
          this.orderData.productNames = coverages[0].productFeeName;
        }
      } catch (e) {}
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.pay {
  height: 100vh;
  padding: 0 10px;
  position: relative;
  font-size: 14px;
  .box {
    padding: 10px;
    margin-bottom: 10px;
    background-color: $white;
    border-radius: 5px;
  }
  .row {
    padding: 10px 5px;
    border-bottom: 1px solid #ebedf0;
  }
  .btn {
    margin-top: 30px;
  }
}
</style>
